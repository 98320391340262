@import "../assets/vendor/bootstrap/css/bootstrap.css";

@import "variables.scss";
@import "mixins.scss";
@import "global.scss";
@import "navbar.scss";
@import "masthead.scss";
@import "post.scss";
@import "contact.scss";
@import "footer.scss";
@import "bootstrap-overrides.scss";

.page-content {
  padding-top: 2em;
  padding-bottom: 2em;
}

.guide-content, .story-content, .page-content, .gallery-content {
  border: 1px solid $gray-300;
  //border: 1px solid #ece7d3;
  border-radius: 3px;
  background: $white;
  padding: 1em 3em;
  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.2em;
  }
}

.kb-content {
  @extend .page-content;
  padding: 1em 2em;
  margin-right:.75em;
  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
      margin-left: -1em;
      padding-left: 1em;
      border-left: 5px solid #72a6d8;
    }
  }
}

.breadcrumb {
  font-family:"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .75em;
  background: $gray-300;
  .breadcrumb-item {
    margin-top: auto;
    margin-bottom: auto;
  }
  #search-container {
    float:right;
  }
}

@media only screen and (max-height: 90vh) {
  body {
    background-color: lightblue;
  }
}

.kb-nav {
  font-family:"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .75em;
  margin-right:1em;
  a {
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
  i.fa {
    float:right;
    color: $gray-600;
  }
  ul {
    list-style: none;
  }
  & > ul {
    padding:0;
    margin:0;
    font-weight: 700;
    li {
      &.nav-parent {
        border-bottom: 1px solid $gray-300;
        padding-bottom: 1em;
        margin-bottom: 1em;
      }
      padding:0;
      margin:0;
      margin-bottom: 1rem;
      & > ul {
        padding-left:1em;
        margin:.5em 0;
        font-weight: 500;
        border-left: 2px solid #ddd;
        li {
          padding:.25em 0;
          margin:0;
        }
      }
    }
  }
}

#inline-toc {
  font-family:"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: .75em;

  &.navbar, &.nav {
    padding-left:15px;
    display: block;
    .nav-link {
      display: inline;
      padding: 0;
      &.active {
        color: $primary;
        text-decoration: underline;
      }
    }
  }
  .sticky-top {
    padding-top:35px;
  }
  font-size: .85em;
  label {
    font-weight: 700;
  }
  ol, ul {
    list-style: none;
    padding-left:0;
    li {
      margin-bottom: .25em;
    }
  }
}

.page-heading-container {
  background-repeat: no-repeat;
  background-position: center bottom;
}

.tag-list {
  text-align: right;
}

.todo {
  padding: .2em;
  background-color: #fcf8e3;
  span {
    font-style: italic;
  }
  &.completed {
    text-decoration: line-through;
    &::after {
      content: ' (Done)';
      text-decoration: none;
      display:inline-block;
      margin-left:.3em;
    }
  }
}

.breadcrumb {
  .breadcrumb-item {
    .fa {
      color: $gray-600;
      &:hover,
      &:focus,
      &:active {
        color: $primary;
      }
    }
  }
}

.post-preview {
  p {
    margin-top: .5em;
  }
}

.btn-blog-index {
  display: inherit;
}

.alert {
  .fa {
    float: left;
    margin: .25em .75em .5em 0;
  }
}

.post-heading, .page-heading {
  h1 {
    color: $brand-dark-blue;
  }
}

.post-preview {
  h2 {
    margin-bottom:0;
  }
  .post-meta {
    color: $gray-700;
    font-style: italic;
    font-size: .85em;
    margin:.25em 0 0 .5em;
  }
}

.guide-card, .story-card {
  img.card-img-top {
    height:8em;
    object-fit: cover;
  }
  &:hover {
    a {
      text-decoration: none;
    }
    border-color: $primary;
  }
}

.gallery-card {
  img.card-img-top {
    height:50vh;
    object-fit: contain;
  }
  &:hover {
    border-color: $primary;
  }
}

.story-image-row {
  img {
    margin-bottom:1em;
    max-height: 75vh;
  }
}

.home-container {
  .row {
    margin-top:30px;
    &:first-child {
      margin-top:0;
    }
  }
  .home-widget {
    p:first-of-type {
      margin-top:0;
    }
    p:last-of-type {
      margin-bottom:0;
    }

    h2 {
      margin-bottom:20px;
      a:hover {
        text-decoration: none;
      }
    }

    .img-thumbnail {
      max-width:33%;
    }

    &.featured-image {
      .img-thumbnail {
        max-width:100%;
      }
    }

    .post-preview > a > h3.post-title {
      font-size:24px;
      &:first-of-type {
        margin-top: 0;
      }
    }

  }

  .salute {
    font-family: inherit;
    font-size: inherit;
  }

}

