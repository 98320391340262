// Styling for the masthead
header.masthead {
  // TIP: Background images are set within the HTML using inline CSS!
  margin-bottom: 0;
  background: no-repeat center center;
  //background-color: $gray-600;
  background-attachment: scroll;
  position: relative;
  @include background-cover;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background-color: $gray-900;
    //opacity: 0.5;
  }
  .page-heading,
  .post-heading,
  .site-heading {
    padding: 200px 0 150px;
    color: #3f5367;
    @media only screen and (min-width: 768px) {
      padding: 2em;
    }
  }

  .page-heading-container {
    @media only screen and (max-width: 768px) {
      background:none !important;
      .page-heading {
        padding: 2em 2em 0 2em;
        margin-bottom: .5em;
      }
    }
  }

  .page-heading,
  .site-heading {
    text-align: center;
    h1 {
      font-size: 50px;
      margin-top: 0;
      @media only screen and (min-width: 768px) {
        &.page-title-shadow {
          text-shadow:  1px 1px 1px #5b7792,
                        1px 2px 1px #2d3d4b,
                        1px 3px 1px #273742,
                        1px 8px 6px rgba(16,16,16,0.4),
                        1px 12px 10px rgba(16,16,16,0.2),
                        1px 25px 15px rgba(16,16,16,0.2),
                        1px 30px 60px rgba(16,16,16,0.4);
        }
      }
    }
    .subheading {
      font-size: 24px;
      font-weight: 300;
      line-height: 1.1;
      display: block;
      margin: 10px 0 0;
      @include sans-serif-font;
    }
    @media only screen and (min-width: 768px) {
      h1 {
        font-size: 70px;
      }
    }
  }
  .post-heading {
    h1 {
      font-size: 35px;
    }
    .meta,
    .subheading {
      line-height: 1.1;
      display: block;
    }
    .subheading {
      font-size: 24px;
      font-weight: 600;
      margin: 10px 0 30px;
      @include sans-serif-font;
    }
    .meta {
      font-size: .8em;
      color: $gray-700;
      font-weight: 300;
      font-style: italic;
      margin-left:.25em;
      @include serif-font;
      a {
        color: $white;
      }
    }
    @media only screen and (min-width: 768px) {
      h1 {
        font-size: 55px;
      }
      .subheading {
        font-size: 30px;
      }
    }
  }
}
